<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="测评数据" name="first">
        <div class="tc-evaluate_export">
          <el-row>
            <!-- 查询 -->
            <el-col :span="6">
              <div class="form">
                <el-form
                  ref="form"
                  :model="form"
                  label-width="130px"
                  size="mini"
                >
                  <el-form-item label="选择报告类型：">
                    <el-select v-model="form.classify_id">
                      <el-option
                        :label="item.classify_name"
                        :value="item.id"
                        v-for="item in evaluateClass"
                        :key="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="按部门导出：">
                    <el-select v-model="form.department_id">
                      <el-option
                        v-for="item in departmentList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        >{{ item.name }}</el-option
                      >
                    </el-select>
                  </el-form-item>

                  <el-form-item label="性别：">
                    <el-radio-group v-model="form.sex">
                      <el-radio label="1">男</el-radio>
                      <el-radio label="0">女</el-radio>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="出生日期：">
                    <el-date-picker
                      v-model="form.age"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </el-form-item>
                  <!-- <el-form-item label="年龄" >
                    <el-input
                      type="number"
                      v-model="form.ages"
                      maxlength="16"
                      :min="0"
                    ></el-input>
                  </el-form-item> -->
                  <el-form-item label="用户编号：">
                    <el-input v-model="form.account"></el-input>
                  </el-form-item>
                  <el-form-item label="用户姓名：">
                    <el-input v-model="form.real_name"></el-input>
                  </el-form-item>
                  <el-form-item label="量表名称：">
                    <el-select v-model="form.measure_title">
                      <el-option
                        :label="item.measure_title"
                        :value="item.id"
                        v-for="(item, key) in scaleList"
                        :key="key"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="测评时间范围：">
                    <div class="twoData">
                      <!-- 开始 -->
                      <el-date-picker
                        v-model="form.begin_time"
                        type="date"
                        placeholder="开始日期"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                      <!-- 结束 -->
                      <el-date-picker
                        v-model="form.end_time"
                        type="date"
                        placeholder="结果日期"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                  <el-form-item label="导出排序：">
                    <el-radio-group v-model="form.user_exp">
                      <el-radio label="0">用户排列</el-radio>
                      <el-radio label="1">量表排列</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="exporBtn">导出</el-button>
                    <el-button type="primary" @click="query">查询</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
            <el-col :span="18">
              <!-- 表格 -->
              <div class="content">
                <el-table
                  v-loading="loading"
                  element-loading-text="正在查询,请稍等片刻"
                  empty-text="请先查询数据"
                  :data="
                    tableData.slice(page * pageSize - pageSize, page * pageSize)
                  "
                  style="width: 100%"
                  align="center"
                  height="767"
                  @selection-change="handleSelectionChange"
                >
                  <el-table-column
                    type="selection"
                    width="60"
                  ></el-table-column>
                  <!--------------------------------------- 二级表格------------------------------------------ -->
                  <el-table-column type="expand">
                    <template slot-scope="scope">
                      <el-table :data="scope.row.children" style="width: 100%">
                        <el-table-column
                          type="index"
                          align="center"
                        ></el-table-column>
                        <el-table-column
                          label="因子项"
                          prop="factor_name"
                          align="center"
                        ></el-table-column>
                        <el-table-column
                          label="得分"
                          prop="score"
                          align="center"
                        ></el-table-column>
                      </el-table>
                    </template>
                  </el-table-column>
                  <!-- -------------------------------------------------------------------------------------- -->
                  <el-table-column
                    label="量表名称"
                    prop="measure_title"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    label="用户姓名"
                    prop="real_name"
                    align="center"
                    width="150"
                  ></el-table-column>
                  <el-table-column
                    label="编号"
                    prop="account"
                    align="center"
                    width="150"
                  ></el-table-column>
                  <el-table-column
                    label="年龄"
                    prop="age"
                    align="center"
                    width="80"
                  ></el-table-column>
                  <el-table-column
                    label="性别"
                    prop="sex"
                    align="center"
                    width="80"
                  ></el-table-column>
                  <el-table-column
                    label="测评时间"
                    prop="create_time"
                    align="center"
                    width="200"
                  ></el-table-column>
                </el-table>
              </div>
              <!-- 页码 -->
              <div class="page">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :page-size="pageSize"
                  :page-sizes="[100, 200, 400, 800, 1600, 3200]"
                  layout="total,sizes, prev, pager, next, jumper"
                  :total="total"
                ></el-pagination>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>

      <el-tab-pane label="预警数据" name="second">
        <!-- 查询 -->
        <el-col :span="6">
          <div class="form">
            <el-form ref="form2" :model="form2" label-width="130px" size="mini">
              <el-form-item label="用户编号：">
                <el-input v-model="form2.account"></el-input>
              </el-form-item>
              <el-form-item label="用户姓名：">
                <el-input v-model="form2.real_name"></el-input>
              </el-form-item>
              <el-form-item label="按部门导出：">
                <el-select v-model="form2.did" clearable>
                  <el-option
                    v-for="item in departmentList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    >{{ item.name }}</el-option
                  >
                </el-select>
              </el-form-item>
              <el-form-item label="量表名称：">
                <el-select v-model="form2.mid" clearable>
                  <el-option
                    :label="item.measure_title"
                    :value="item.id"
                    v-for="(item, key) in scaleList"
                    :key="key"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="测评时间范围：">
                <div class="twoData">
                  <!-- 开始 -->
                  <el-date-picker
                    clearable
                    v-model="form2.stime"
                    type="date"
                    placeholder="开始日期"
                    value-format="timestamp"
                  >
                  </el-date-picker>
                  <!-- 结束 -->
                  <el-date-picker
                    clearable
                    v-model="form2.etime"
                    type="date"
                    placeholder="结果日期"
                    value-format="timestamp"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <!-- <el-form-item label="导出排序：">
                <el-radio-group v-model="form.user_exp">
                  <el-radio label="0">用户排列</el-radio>
                  <el-radio label="1">量表排列</el-radio>
                </el-radio-group>
              </el-form-item> -->
              <el-form-item>
                <el-button type="primary" @click="exporBtn2">导出</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import axios from "axios";
import { loadDepartmentData } from "@/api/comm.js";
import { getExcel, postExcel, warn_measure_export } from "@/api/information.js";

import {
  getMeasureClassifyList,
  getMeasureByClassify,
} from "@/api/evaluate.js";
export default {
  data() {
    return {
      activeName: "first",
      loading: false,
      tableData: [],
      multipleSelection: [],

      //id筛选   ids
      //部门筛选   did
      //量表筛选   mid
      //时间筛选  stime  etime
      //用户编号筛选  account
      //用户姓名筛选  real_name
      form2: {
        ids: "",
        did: "",
        mid: "",
        stime: "",
        etime: "",
        account: "",
        real_name: "",
      },
      form: {
        classify_id: "",
        department_id: "",
        sex: "",
        age: "",
        warning: "", //1预警.0普通
        real_name: "",
        measure_title: "",
        begin_time: "",
        end_time: "",
        user_exp: "",
      },
      // 部门
      departmentList: [],
      // 报告类型
      evaluateClass: [],
      scaleList: [],
      // 页码控制
      page: 1,
      total: 0,
      pageSize: 100,
      filtrsName: "", //过滤表单
    };
  },
  created() {
    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
    // 获取报告类型
    getMeasureClassifyList({ page: 1, size: 99999 }).then((res) => {
      this.evaluateClass = res.data.data;
    });
    // 获取量表名称
    this.loadScaleData();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 导出下载
    exporBtn() {
      
      // if (this.multipleSelection.length <= 0) {
      //   this.$message({
      //     showClose: true,
      //     message: "步骤错了哦，请先选择需要导出的用户数据",
      //     type: "warning",
      //   });
      //   return;
      // }
      let excelToken = ""; //存储  后台返回的 量表Token
      console.log(1111,{ query_id: this.multipleSelection.toString()});
      
      postExcel({ query_id: this.multipleSelection.toString() }).then((res) => {
        if (res.code == 400200) {
          excelToken = res.data.excel_token;
          // console.log("返回数据",excelToken);
          // return
          let {
            classify_id,
            department_id,
            sex,
            age,
            real_name,
            measure_title,
            begin_time,
            end_time,
            user_exp,
          } = this.form;
          // 封装 url 链接
          let str = `?classify_id=${classify_id}&department_id=${department_id}&sex=${sex}&age=${age}&real_name=${real_name}&measure_title=${measure_title}&begin_time=${begin_time}&end_time=${end_time}&user_exp=${user_exp}&excel_token=${excelToken}&download`;

          console.log("消失",this.$baseurl);
          // return
          // 下载
          window.location.href = this.$baseurl + `/get_excel${str}`;
          
        }
      });
    },
    // 导出下载
    exporBtn2() {
      let { did, mid, stime, etime, account, real_name } = this.form2;
      stime = (stime * 1) / 1000;
      etime = (etime * 1) / 1000;
      // 封装 url
      let str = `?&account=${account}&did=${did}&real_name=${real_name}&mid=${mid}&stime=${stime}&etime=${etime}&export=1`;
      // 下载
      window.location.href = this.$baseurl + `/warn_measure_export${str}`;
    },
    // 量表数据请求
    async loadScaleData() {
      // var res = await getMeasureByClassify();
      var res = await getMeasureByClassify();
      //console.log(res);
      res.data.forEach((v) => {
        v.status_measure.forEach((v) => {
          this.scaleList.push(v);
        });
      });
      //console.log(this.scaleList);
    },
    // 查询
    query() {
      this.loading = true;
      this.form.page = this.page;
      this.form.pagesize = this.pageSize;
      getExcel(this.form).then((res) => {
        if (res.code == 400200) {
          let data = [];
          for (let k in res.data) {
            for (let k2 in res.data[k]) {
              let a = res.data[k][k2].info;
              a.children = res.data[k][k2].arr;
              data.push(a);
            }
          }
          this.tableData = data;
          this.total = this.tableData.length;
          this.loading = false;
        }
      });
    },
    // 当前页面获取的最大数量
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    // 页码监听
    handleCurrentChange(val) {
      // 根据page加载对应数据
      this.page = val;
      this.query();
    },
    // 单独表单搜索过滤
    tablefiltrs() {
      if (this.filtrsName == "") {
        this.query();
      } else {
        this.$nextTick(() => {
          this.tableData = this.tableData.filter((v, k) => {
            var reg = new RegExp(this.filtrsName);
            if (v.measure_title.match(reg)) {
              return v;
            }
          });
        });
      }
    },
    // 全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
    },
  },
};
</script>

<style lang="less">
.tc-evaluate_export {
  margin-top: 20px;
  // 查询
  .form {
    .el-input__inner {
      width: 200px;
    }
  }
  // 表单过滤
  .filtrate {
    display: flex;
    justify-content: flex-end;
    .el-input {
      width: 300px;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 770px;
    // margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }

  // 页码
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>